<template>
  <b-sidebar
    id="add-new-spec-sidebar"
    :visible="isAddNewSpecSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-spec-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Spec" : actionType == 1 ? " Update Spec" : "Delete Spec"}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Spec No"
            rules="required"
          >
            <b-form-group
              label="Spec No"
              label-for="spec-no"
            >
              <b-form-input
                id="spec-no"
                v-model="currentSpec.specNo"
                :disabled="actionType==2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Spec No"
              />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
          </validation-provider>

          <b-form-group label="WorkCenter">
            <treeselect
              id="workCenterId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentWorkCenter"
              label="name"
              :options="workCenters"
              :disabled="actionType==2"
              class="select-size-lg">
              <template #option="{ id, name }">
                  <span> {{ name }}</span><br />
                  <small class="text-muted"> {{ id }} </small>
              </template>
            </treeselect>
          </b-form-group>

          <b-form-group label="Product">
            <v-select
              id="productId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="currentProduct"
              label="name"
              :options="products"
              :disabled="actionType==2"
              multiple
              class="select-size-lg">
              <template #option="{ id, name }">
                  <span> {{ name }}</span><br />
                  <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            label="Description"
            label-for="spec-description"
          >
            <b-form-input
              id="spec-description"
              v-model="currentSpec.description"
              :disabled="actionType==2"
              autofocus
              trim
              placeholder="Description"
            />
          </b-form-group>

          <b-form-group label="Mould">
            <v-select
                id="mould"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="currentMould"
                label="code"
                :options="moulds"
                :disabled="actionType==2"
                class="select-size-lg">
                  <template #option="{ id, code }">
                      <span> {{ code }}</span><br />
                      <small class="text-muted"> {{ id }} </small>
                  </template>
            </v-select>
          </b-form-group>

        <b-form-group
            label="Machine"
            label-for="spec-machine"
          >
            <b-form-input
              id="spec-machine"
              v-model="currentSpec.machine"
              :disabled="actionType==2"
              autofocus
              trim
              placeholder="Machine"
            />
          </b-form-group>
          
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Rev"
                label-for="spec-rev"
              >
                <b-form-input
                  id="spec-rev"
                  v-model="currentSpec.rev"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Rev"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
               <b-form-group
                label="Material Code"
                label-for="spec-materialCode"
              >
                <b-form-input
                  id="spec-materialCode"
                  v-model="currentSpec.materialCode"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Material Code"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Ton"
                label-for="spec-ton"
              >
                <b-form-input
                  id="spec-ton"
                  v-model="currentSpec.ton"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Ton"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Screw Diameter"
                label-for="spec-screwDiameter"
              >
                <b-form-input
                  id="spec-ton"
                  v-model="currentSpec.screwDiameter"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Screw Diameter"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nominal"
                label-for="spec-nominal"
              >
                <b-form-input
                  id="spec-nominal"
                  v-model="currentSpec.nominal"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Nominal"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Cavity"
                label-for="spec-cavity"
              >
                <b-form-input
                  id="spec-cavity"
                  v-model="currentSpec.cavity"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Cavity"
                />
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Min"
                label-for="spec-min"
              >
                <b-form-input
                  id="spec-min"
                  v-model="currentSpec.min"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Min"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Max"
                label-for="spec-max"
              >
                <b-form-input
                  id="spec-max"
                  v-model="currentSpec.max"
                  :disabled="actionType==2"
                  autofocus
                  trim
                  placeholder="Max"
                />
              </b-form-group>
            </b-col>
          </b-row>



          <b-form-checkbox 
              class="mt-2"
              :disabled="actionType==2"
              id="input-isSpecActive"
              v-model="currentSpecIsActive"
            >
              Is Active
            </b-form-checkbox>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="actionType != 2"
              class="mr-2"
              type="submit"
            >
             {{ currentSpec==null|| currentSpec.id==null? 'Add':'Update'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              v-if="actionType == 1"
              class="mr-2"
              type="button"
              @click="cloneSpec"
            >
             {{ 'Clone'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              v-if="currentSpec!=null&& currentSpec.id!=null && actionType == 2"
              variant="outline-danger"
              @click="deleteSpec"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox, BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {inject,toRefs, ref,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BSidebar,
    BRow, 
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    Treeselect
  },
  created() {
    this.fetchWorkCenters();
    this.fetchProducts();
    this.fetchMoulds();
  },
   methods:{
    removeEmptyChildren(node) {
      if (node.children.length === 0) {
        delete node.children;
      } else {
        node.children.forEach(child => this.removeEmptyChildren(child));
      }
    },
    formatParentChildForTreeSelect(data) {
     const idToData = {};
      const rootItems = [];

      data.forEach(item => {
        const { id, name, parentID } = item;
        const newItem = { id, label: name, children: [] };

        idToData[id] = newItem;

        if (parentID === 0) {
          rootItems.push(newItem);
        } else {
          const parentItem = idToData[parentID];
          if (parentItem) {
            if (!parentItem.children) {
              parentItem.children = [];
            }
            parentItem.children.push(newItem);
          }
        }
      });

      return rootItems;
    },
    fetchWorkCenters() {
      this.workCenters = [];
      this.$store.dispatch('pamis-spec/fetchWorkCenters')
        .then((result) => {
          if (result != null) {
            const workCenterList = result.data.filter(x =>x.isDelete == false && x.isActive == true)
            var list = this.formatParentChildForTreeSelect(workCenterList);
            list.forEach(node => this.removeEmptyChildren(node));
            this.workCenters =  list;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Workcenter By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    fetchMoulds() {
      this.$store.dispatch('pamis-spec/fetchAllMould')
        .then((result) => {
          if (result != null) {
            this.moulds = result.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Mould By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    deleteSpec(){
       this.$store.dispatch('pamis-spec/deleteSpec', {id : this.currentSpec.id})
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-spec-sidebar-active', false)
          this.notification()
        });
    },
    cloneSpec(){
      const specRequest = {};
      this.currentSpec.workCenterId = this.currentWorkCenter;
      specRequest.specification = this.currentSpec;
      specRequest.specification.referenceSpecId = this.currentSpec.id;
      specRequest.specification.isActive = this.currentSpecIsActive;

      if (this.currentProduct != null && this.currentProduct.length > 0) {
        specRequest.specification.products = [];

        this.currentProduct.forEach(product => {
          specRequest.specification.products.push(product);
        });
      }

       this.$store.dispatch('pamis-spec/cloneSpec', specRequest)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-spec-sidebar-active', false)
          this.notification()
        });
    },
    submitForm(){
      if (this.currentWorkCenter == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'WorkCenter can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return false;
      }
      
      var process = "pamis-spec/addSpec";

      if(this.currentSpec !=null &&  this.currentSpec.id != null){
        process = "pamis-spec/updateSpec";
      } 

      this.currentSpec.workCenterId = this.currentWorkCenter;
      this.currentSpec.isActive = this.currentSpecIsActive;

      if (this.currentMould != null) {
        this.currentSpec.mould = this.currentMould;
        this.currentSpec.mouldId = this.currentMould.id;
      }

      this.currentSpec.productIds = [];
      this.currentSpec.products = [];

      if (this.currentProduct != null && this.currentProduct.length > 0) {
          this.currentProduct.forEach(product => {
            this.currentSpec.productIds.push(product.id);
            this.currentSpec.products.push(product);
          });
      }

      const specRequest = {};

      specRequest.specification = this.currentSpec;
        
      this.$store.dispatch(process, specRequest)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-spec-sidebar-active', false)
          this.notification()
        });

    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
    fetchProducts() {
      this.$store.dispatch('pamis-spec/fetchProducts')
        .then((result) => {
          if (result != null) {
            this.products =  result.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Product By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
  },
  
  watch: {
     specdata:   {
        handler:function (n, o)   {
          if (n==null||n==undefined || n.selectedSpec.id == null)
          {
            this.currentSpec={
              id:null,
              specNo:null,
              rev:null,
              description:null,
              materialCode:null,
              mouldCode:null,
              machine:null,
              ton:null,
              screwDiameter:null,
              nominal:null,
              min:null,
              max:null,
              cavity:null,
            }

            this.currentWorkCenter = n.selectedSpec.currentWorkCenter;
            this.currentProduct = null;
            this.currentMould = null;
          } else { 
              this.currentSpec = n.selectedSpec;
              this.currentSpecIsActive = n.selectedSpec.isActive;
              this.currentMould = n.selectedSpec.mould;

              if (n.selectedSpec.isChanged) {
                this.currentWorkCenter = null;
                this.currentProduct = null;
                n.selectedSpec.isChanged = false;
              }

              if (this.currentSpec.id != null) {
                if (this.currentWorkCenter == null) {
                  this.currentWorkCenter = n.selectedSpec.workCenterId;
                }
                
                if (this.currentProduct == null) {
                  var products = n.selectedSpec.products;
                  this.currentProduct = products.filter(x=> x.isDelete == false);
                }
              }
          }
        },
        deep: true
    }
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSpecSidebarActive',
    event: 'update:is-add-new-spec-sidebar-active',
  },
  props: {
    isAddNewSpecSidebarActive: {
      type: Boolean,
      required: true,
    },
     actionType: {
      type: Number,
      required: true,
    },
  },
   
  data() {
    return {
      required,
      alphaNum,
      email,
      workCenters : [],
      products : [],
      currentWorkCenter : null,
      currentProduct: null,
      currentSpecIsActive : true,
      currentSpec:{
        id:null,
        specNo:null,
        rev:null,
        description:null,
        materialCode:null,
        mouldCode:null,
        machine:null,
        ton:null,
        screwDiameter:null,
        nominal:null,
        min:null,
        max:null,
        cavity:null,
      },
      moulds : [],
      currentMould : null,
    }
  },
   
  inject: ['specdata'],

  setup(props,  { emit }) {
    const blankSpecData = {
      id:null,
      specNo:null,
      rev:null,
      description:null,
      materialCode:null,
      mouldCode:null,
      machine:null,
      ton:null,
      screwDiameter:null,
      nominal:null,
      min:null,
      max:null,
      cavity:null,
    }

    const specData = ref(JSON.parse(JSON.stringify(blankSpecData)))
    const resetSpecData = () => {
      specData.value = JSON.parse(JSON.stringify(blankSpecData))
      emit('refetch-data')
      emit('update:is-add-new-spec-sidebar-active', false)
    }
      
    const onSubmit = () => {
      store.dispatch('pamis-spec/addSpec', currentSpec)
        .then(() => {
        })
    }
     
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSpecData)

    return {
      specData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-spec-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
