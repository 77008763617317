<template>
  <div>
    <spec-add-new
      :is-add-new-spec-sidebar-active.sync="isAddNewSpecSidebarActive"
      :selectedSpec="selectedSpec"
      :actionType="actionType"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      
       <div class="m-2">
        <b-row>
          <b-col md="2">
            <b-form-group label="WorkCenter">
                    <treeselect
                            id="workCenterId"
                            v-model="currentWorkCenter"
                            label="name"
                            :options="row"
                            class="select-size-lg">
                    <template #option="{ id, name }">
                        <span> {{ name }}</span><br />
                        <small class="text-muted"> {{ id }} </small>
                    </template>
                    </treeselect>
                </b-form-group>
          </b-col>

        </b-row>
        
      </div>

      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="newSpec"
                :disabled="currentWorkCenter == null"
              >
                <span class="text-nowrap">Add Spec</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refSpecListTable"
        class="position-relative"
        :items="specList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="search"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <template #cell(isActive)="data">
        <b-badge :variant="isActiveVariant(data.item.isActive)">
            {{ data.item.isActive }}
          </b-badge>
      </template>

        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="editSpec(data.item,1)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>{{$t("Edit/Clone")}}</span>
              </b-dropdown-item>
              <b-dropdown-item  @click="editSpec(data.item,2)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>{{$t("Delete")}}</span>
              </b-dropdown-item>
            </b-dropdown>
        </template> 

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useSpecList from './useSpecList'
import specStoreModule from '../specStoreModule'
import SpecAddNew from './SpecAddNew.vue'
import moment from 'moment'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
   data() {
    return {
      totalRows : 0,
      selectedSpec:null,   
      selectedWorkcenter:null,
      search:'',
      actionType : 0,
      specList : [],
      currentWorkCenter : null,
      parameterList : [],
      tags : []
    }
  },
  watch:{
    currentWorkCenter(item) {

      if (item > 0) {
        this.fetchSpecByWorkcenter(item)
      }
    }
  },
    provide() {
        const specdata = {}

        Object.defineProperty(specdata, "selectedSpec", {
            enumerable: true,
            get: () => this.selectedSpec,
        })

        return {
            specdata
        }
    },
  computed: {
    isActiveVariant() {
        const priorityColor = {
          false: 'light-danger',
          true: 'light-success'
        }

        return priority => priorityColor[priority]
      },
  },
  methods:{
    fetchSpecByWorkcenter(item){
       store.dispatch('pamis-spec/fetchSpecByWorkcenter', item)
        .then(response => {
          if(response.data != null && response.data.length > 0){
            var specList = response.data.filter(x=> x.isDelete == false);
            
            specList.forEach(element => {
              if (element.mould != null) {
                element.mouldCode = element.mould.code
              }
            });

            this.specList = specList
            this.totalRows = specList.length;
          } else {
            this.specList = null
            this.totalRows = 0;
          }

        })
    },
    refetchData(){
      this.fetchSpecByWorkcenter(this.currentWorkCenter);
    },
    editSpec(e,actionType)
    {
      this.actionType = actionType;
      this.selectedSpec=e;
      this.selectedSpec.isChanged=true;
      this.isAddNewSpecSidebarActive=true;
    },
    newSpec()
    {
      this.selectedSpec={
        id:null,
        specNo:null,
        rev:null,
        description:null,
        materialCode:null,
        mouldCode:null,
        machine:null,
        ton:null,
        screwDiameter:null,
        nominal:null,
        min:null,
        max:null,
        cavity:null,
        currentWorkCenter: this.currentWorkCenter
      };

      this.actionType = 0;
      this.isAddNewSpecSidebarActive=true;
    },
    format_date(value){
      if(value){
          var myDate = new Date('0001-01-01T00:00:00Z');
          var dateItem = moment(value);

          if(dateItem > myDate){
              return moment(value).locale("en").format("LLL");
          }
      }
    },
    format_number(value){
      if(value){
        var newValue = Number(value);
        if (newValue > 0) {
          return newValue.toFixed(3);
        } else {
          return value;
        }  
      }
    }
  },
  components: {
    SpecAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    vSelect,
    Treeselect
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'pamis-spec'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, specStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewSpecSidebarActive = ref(false)
    const isAddNewParameterSidebarActive = ref(false)

    const {
      row,
      tableColumns,
      perPage,
      currentPage,
      totalSpecs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refSpecListTable,
      refetchData,
      search
    } = useSpecList()

    return {
      isAddNewSpecSidebarActive,
      isAddNewParameterSidebarActive,
      row,
      tableColumns,
      perPage,
      currentPage,
      totalSpecs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refSpecListTable,
      refetchData,
      search,
      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
